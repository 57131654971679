import { ClubBoat } from '@mabadive/app-common-model';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppIconsBoatDeparture } from 'src/business/_core/modules/layout/icons';
import { AppIcon } from 'src/business/_core/modules/layout/icons/AppIcon.type';
import { useDiveCenterResume } from 'src/business/club/data/hooks';

export type ClubBoatLabelBoat = Pick<
  ClubBoat,
  'color' | 'name' | 'isSpecial' | 'departureType' | 'details'
>;

export const ClubBoatLabelShort = ({
  clubBoatId,
  clubBoat: clubBoatInput,
  className,
  iconClassName = 'w-4 h-4',
}: {
  clubBoatId?: string;
  clubBoat?: ClubBoatLabelBoat;
  className?: string;
  iconClassName?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();

  const clubBoat: ClubBoatLabelBoat = useMemo(
    () =>
      clubBoatId
        ? diveCenterResume.boats.find((x) => x._id === clubBoatId)
        : clubBoatInput,
    [clubBoatId, clubBoatInput, diveCenterResume.boats],
  );

  const Icon: AppIcon = AppIconsBoatDeparture[clubBoat.departureType];
  return !clubBoat ? null : (
    <Tippy delay={[300, 100]} content={clubBoat.name}>
      <div
        className={clsx(
          'self-end items-center gap-px py-px px-1 border bg-white text-xs',
          className,
        )}
        style={{
          color: clubBoat.color,
          borderColor: clubBoat.color,
        }}
      >
        {Icon && <Icon className={clsx('', iconClassName)} />}
        {clubBoat.details?.shortName}
      </div>
    </Tippy>
  );
};
