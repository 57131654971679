import clsx from 'clsx';
import React from 'react';
import {
  AppFormAutoSubmit,
  AppLoader,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { EcommerceProductArticleEditorPanelActionBar } from './EcommerceProductArticleEditorPanelActionBar';
import { EcommerceProductArticleEditorPanelCreateForm } from './EcommerceProductArticleEditorPanelCreateForm';
import { EcommerceProductArticleEditorPanelEditForm } from './EcommerceProductArticleEditorPanelEditForm';
import {
  EcommerceProductArticleEditorLocalState,
  EcommerceProductArticleEditorProps,
  useEcommerceProductArticleEditorPanelLocalState,
} from './useEcommerceProductArticleEditorPanelLocalState.hook';

export const EcommerceProductArticleEditorPanel = (
  inputProps: EcommerceProductArticleEditorProps,
) => {
  const localState: EcommerceProductArticleEditorLocalState =
    useEcommerceProductArticleEditorPanelLocalState(inputProps);

  const { state, actions } = localState;

  return (
    <AppPageContainerWithFixedBars
      className="max-w-screen-2xl"
      contentClassName="bg-gray-50"
      footerBar={() => (
        <EcommerceProductArticleEditorPanelActionBar
          className="sm:hidden"
          localState={localState}
          isFixedBottom={true}
        />
      )}
    >
      <div className="app-p-content">
        {state.persistInProgress ? (
          <div className={'my-4 app-card app-p-content--lg'}>
            <AppLoader type="persist" className="my-4" />
          </div>
        ) : (
          <div className={clsx('h-full flex flex-col app-card app-px-content')}>
            <div className="text-xl font-bold py-2 text-gray-600 uppercase">
              {state.mode === 'create' ? 'Nouveau tarif' : ''}
            </div>
            <AppFormAutoSubmit
              className="relative py-2"
              hasChanges={true}
              onSubmit={actions.submit}
            >
              {/* {appWebConfig.debug.autoFill && (
          <div
            className="absolute right-0 p-3 cursor-pointer text-gray-300 hover:text-blue-400"
            onClick={() => autoFillForm()}
          >
            <AppIcons.autoFill className="w-6 h-6" color="primary" />
          </div>
        )} */}
              {/* <h2 className="mx-1 mt-6 mb-2 text-sm font-bold text-app-primary uppercase">
                {'Détails du produit'}
              </h2> */}
              {state.mode === 'edit' ? (
                <EcommerceProductArticleEditorPanelEditForm
                  localState={localState}
                />
              ) : (
                <EcommerceProductArticleEditorPanelCreateForm
                  localState={localState}
                />
              )}
            </AppFormAutoSubmit>
            <div className="flex-grow"></div>
            <EcommerceProductArticleEditorPanelActionBar
              className="hidden sm:block sticky -bottom-8 pt-8"
              localState={localState}
            />
          </div>
        )}
      </div>
    </AppPageContainerWithFixedBars>
  );
};
