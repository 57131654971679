/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import {
  AppButton,
  AppFormAutoSubmit,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';

import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { ClubSettingsProductOffersDialogActionsBar } from './ClubSettingsProductOffersDialogActionsBar';
import {
  ClubSettingsProductOffersDialogState,
  ClubSettingsProductOffersFormModel,
} from './_model';
import { ClubSettingsProductOffersForm } from './form';
import {
  ClubSettingsProductOffersDialogLocalState,
  useClubSettingsProductOffersDialogLocalState,
} from './useClubSettingsProductOffersDialogLocalState.hook';
import { useClubSettingsProductOffersDialogSubmit } from './useClubSettingsProductOffersDialogSubmit.hook';

export const ClubSettingsProductOffersDialog: FC<ClubSettingsProductOffersDialogState> =
  (inputState) => {
    const {
      isOpen,
      initialState,
      onCancel,
      onConfirm,
      openDialog,
      closeDialog,
    } = inputState;

    const { defaultValue } = initialState;

    const localState: ClubSettingsProductOffersDialogLocalState =
      useClubSettingsProductOffersDialogLocalState({
        initialState,
      });

    const {
      state: { form },
    } = localState;

    const {
      register,
      handleSubmit,
      watch,
      formState,
      control,
      setValue,
      reset,
    } = form;

    const formValue: ClubSettingsProductOffersFormModel = watch();

    const submitForm = useClubSettingsProductOffersDialogSubmit({
      handleSubmit,
      localState,
      inputState,
    });

    const hasChanges = form.formState.isDirty;

    return !isOpen ? null : (
      <AppPageContainerWithFixedBars
        className="max-w-screen-2xl"
        contentClassName="bg-gray-50"
        footerBar={() => (
          <ClubSettingsProductOffersDialogActionsBar
            className="sm:hidden"
            onCancel={onCancel}
            submitForm={submitForm}
          />
        )}
      >
        <div className="app-p-content">
          <div className="flex justify-end">
            <AppInputBooleanSwitch
              label="Admin"
              value={localState.state.isAdminMode}
              onChange={(isAdminMode) => {
                localState.state.setIsAdminMode(isAdminMode);
              }}
            />
          </div>

          <AppFormAutoSubmit hasChanges={hasChanges} onSubmit={submitForm}>
            <ClubSettingsProductOffersForm
              className="app-card app-p-content"
              localState={localState}
            />
            <div className="hidden sm:block sticky -bottom-8 pt-8 app-card">
              <div className="app-p-content flex gap-4 justify-start">
                <AppButton
                  size="normal"
                  icon={AppIcons.action.cancel}
                  tabIndex={500}
                  color="gray-outline-light"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Annuler
                </AppButton>
                <AppButton
                  type="submit"
                  size="normal"
                  icon={AppIcons.action.save}
                  color="primary-bg"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  Confirmer
                </AppButton>
              </div>
            </div>
          </AppFormAutoSubmit>
        </div>
      </AppPageContainerWithFixedBars>
    );
  };
