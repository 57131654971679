/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { CSSProperties, ReactNode, useMemo } from 'react';
import {
  Control,
  ControllerRenderProps,
  FieldPath,
  Path,
  PathValue,
  UseControllerProps,
  UseControllerReturn,
  useController,
} from 'react-hook-form';
import { AppFormLabel } from '../AppFormLabel';

export type AppFormControlRHFBaseProps_2024_03<T, V = any> = {
  control: Control<T>;
  name: FieldPath<T>;
  required?: boolean;
  disabled?: boolean;
  validation?: {
    email?: boolean;
    pattern?: RegExp;
  } & Pick<UseControllerProps<T>, 'rules'>;
  value?: V;
};

const VALIDATION_EMAIL_PATTERN_ALLOW_TRAILING_LEADING_SPACES =
  /^\s*\S+@\S+\.\S+\s*$/; // https://stackoverflow.com/a/201447 + https://stackoverflow.com/a/22025206

export type AppFormControlRHFRenderComponentProps<T> =
  AppFormControlRHFBaseProps_2024_03<T> &
    Omit<ControllerRenderProps<T, FieldPath<T>>, 'ref'>;

// NOTE 22/03/2024 : repris depuis anim-live, comportement à vérifier et à compléter avec les éventuelles spécificités de AppFormControlRHF_Deprecated avant de généraliser
export function AppFormControlRHF<T, V = any>({
  label,
  labelFontSize = 'text-xs',
  control,
  name,
  defaultValue,
  renderComponent,
  renderError,
  className,
  required: requiredInput,
  requiredStyle,
  disabled,
  helpDescription,
  validation,
  style,
  onChange,
  isDiveCenterSpecificFeature,
  visibility,
}: AppFormControlRHFBaseProps_2024_03<T> & {
  label?: React.ReactNode;
  defaultValue?: PathValue<T, Path<T>>;
  helpDescription?: string;
  labelFontSize?: string;
  requiredStyle?: 'show-optional-label';
  renderComponent: (
    props: AppFormControlRHFRenderComponentProps<T>,
  ) => ReactNode;
  renderError?: (attrs: {
    baseProps: AppFormControlRHFBaseProps_2024_03<T>;
    state: UseControllerReturn<T, any>;
    error: any;
  }) => ReactNode | null;
  className?: string;
  style?: CSSProperties;
  onChange?: (value: V) => void;
  isDiveCenterSpecificFeature?: boolean;
  visibility?: 'super-admin';
}) {
  const pattern = useMemo(() => {
    if (validation?.email) {
      return VALIDATION_EMAIL_PATTERN_ALLOW_TRAILING_LEADING_SPACES;
    }
    if (validation?.pattern) {
      return validation?.pattern;
    }
    return undefined;
  }, [validation?.email, validation?.pattern]);

  const required = useMemo(
    () => requiredInput || !!validation?.rules?.required,
    [requiredInput, validation?.rules?.required],
  );

  const { rules }: Pick<UseControllerProps<T, FieldPath<T>>, 'rules'> =
    useMemo(() => {
      return {
        rules: {
          ...(validation?.rules ?? {}),
          required:
            validation?.rules?.required ?? required ? 'required' : undefined,
          pattern,
        },
      };
    }, [pattern, required, validation?.rules]);

  const state = useController<T>({
    name: name as unknown as FieldPath<T>,
    control,
    rules,
    defaultValue,
  });
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = state;

  const baseProps: AppFormControlRHFRenderComponentProps<T> = useMemo(() => {
    const props: AppFormControlRHFRenderComponentProps<T> = {
      ...inputProps,
      control,
      name,
      required,
      disabled,
      onChange: (value: V) => {
        inputProps.onChange(value);
        onChange && onChange(value);
      },
    };
    // pass validation rules to children
    if (validation?.rules?.min) {
      (props as any).min = validation?.rules?.min;
    }
    if (validation?.rules?.max) {
      (props as any).max = validation?.rules?.max;
    }
    if (validation?.rules?.maxLength) {
      (props as any).maxLength = validation?.rules?.maxLength;
    }
    if (validation?.rules?.minLength) {
      (props as any).minLength = validation?.rules?.minLength;
    }
    return props;
  }, [
    inputProps,
    control,
    name,
    required,
    disabled,
    validation?.rules?.min,
    validation?.rules?.max,
    validation?.rules?.maxLength,
    validation?.rules?.minLength,
    onChange,
  ]);

  const hasError = invalid || !!error;

  return (
    <div
      className={`whitespace-nowrap flex flex-col ${className ?? ''}`}
      style={style}
    >
      <AppFormLabel
        required={required}
        requiredStyle={requiredStyle}
        className={'form-label pl-1'}
        label={label}
        labelFontSize={labelFontSize}
        hasError={hasError}
        disabled={disabled}
        helpDescription={helpDescription}
        isDiveCenterSpecificFeature={isDiveCenterSpecificFeature}
        visibility={visibility}
      >
        <div className="form-input">{renderComponent(baseProps)}</div>
        <div className="text-status-error">
          {hasError ? renderErrorMessage(baseProps) : null}
        </div>
      </AppFormLabel>
    </div>
  );

  function renderErrorMessage(
    baseProps: AppFormControlRHFBaseProps_2024_03<T>,
  ): React.ReactNode {
    const {
      field: { ref, ...inputProps },
      fieldState: { invalid, isTouched, isDirty, error },
      formState: { touchedFields, dirtyFields },
    } = state;
    const msg = renderError ? renderError({ error, state, baseProps }) : null;
    if (msg) {
      return msg;
    }
    return renderGenericMessages({ state });
  }
}

function renderGenericMessages<T>({
  state,
}: {
  state: UseControllerReturn<any, Path<T>>;
}): React.ReactNode {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = state;

  switch (error?.type) {
    case 'required':
      return 'Champ obligatoire';
    case 'email':
      return 'Adresse e-mail invalide';
    case 'pattern':
      return 'Format invalide';
    // case 'minLength':
    //   if (
    //     attrState.validation.errorContext &&
    //     (attrState.validation.errorContext as any).minLength
    //   ) {
    //     return `Longueur minimale: ${
    //       (attrState.validation.errorContext as any).minLength
    //     } caractères`;
    //   }
    //   return 'Longeur trop courte';
    default: {
      return 'Champ invalide';
    }
  }
}
