/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import {
  AppFormAutoSubmit,
  AppLoader,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useClubResume } from 'src/business/club/data/hooks';
import { CustomerSpaceConfigDialogActionsBar } from './CustomerSpaceConfigDialogActionsBar';
import { CustomerSpaceConfigForm } from './form';
import { CustomerSpaceConfigDialogState } from './model';
import {
  CustomerSpaceConfigDialogLocalState,
  useCustomerSpaceConfigDialogLocalState,
} from './useCustomerSpaceConfigDialogLocalState.hook';
import { useCustomerSpaceConfigDialogSubmit } from './useCustomerSpaceConfigDialogSubmit';

export const CustomerSpaceConfigDialog: FC<CustomerSpaceConfigDialogState> = (
  inputState,
) => {
  const [openExtraCostsFormPanel, setOpenExtraCostsFormPanel] = useState(false);
  const globalClasses = useGlobalClasses();

  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const clubReference = clubResume?.reference;

  const {
    isOpen,
    initialState,
    onCancel,
    onConfirm,
    openDialog,
    closeDialog,
  } = inputState;

  const { defaultValue } = initialState;

  const localState: CustomerSpaceConfigDialogLocalState =
    useCustomerSpaceConfigDialogLocalState({
      initialState,
    });

  const { form } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const submitForm = useCustomerSpaceConfigDialogSubmit({
    handleSubmit,
    localState,
    inputState,
  });

  const hasChanges = form.formState.isDirty;

  return !isOpen ? null : (
    <AppPageContainerWithFixedBars
      className="max-w-screen-2xl"
      contentClassName="bg-gray-50"
      footerBar={() => (
        <CustomerSpaceConfigDialogActionsBar
          onCancel={onCancel}
          submitForm={submitForm}
        />
      )}
    >
      <div className=" app-p-content">
        {/* <h3 className="mt-2 text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
          Espace client
        </h3> */}

        <AppFormAutoSubmit hasChanges={hasChanges} onSubmit={submitForm}>
          <div className={clsx('app-card app-p-content')}>
            <CustomerSpaceConfigForm
              initialState={initialState}
              localState={localState}
            />
            {localState.data.linkGenerationInProgress && (
              <AppLoader type="loading" />
            )}
          </div>
        </AppFormAutoSubmit>
      </div>
    </AppPageContainerWithFixedBars>
  );
};
