/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import {
  AppButton,
  AppFormAutoSubmit,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useClubResume } from 'src/business/club/data/hooks';

import { AppIcons } from 'src/business/_core/modules/layout/icons';
import {
  ClubSettingsGeneralDialogState,
  ClubSettingsGeneralFormModel,
} from '../_model';
import { ClubSettingsGeneralSessionsForm } from '../tabs';
import { ClubSettingsGeneralMainForm } from '../tabs/01.main';
import { ClubSettingsGeneralPurchaseEditForm } from '../tabs/03.purchase/form/ClubSettingsGeneralPurchaseEditForm';
import { ClubSettingsGeneralPaymentsEditForm } from '../tabs/04.payments/form/ClubSettingsGeneralPaymentsEditForm';
import { ClubSettingsGeneralDialogActionsBar } from './ClubSettingsGeneralDialogActionsBar';
import {
  ClubSettingsGeneralDialogLocalState,
  useClubSettingsGeneralDialogLocalState,
} from './useClubSettingsGeneralDialogLocalState.hook';
import { useClubSettingsGeneralDialogSubmit } from './useClubSettingsGeneralDialogSubmit';

export const ClubSettingsGeneralDialog: FC<ClubSettingsGeneralDialogState> = (
  inputState,
) => {
  const [openExtraCostsFormPanel, setOpenExtraCostsFormPanel] = useState(false);
  const globalClasses = useGlobalClasses();

  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const clubReference = clubResume?.reference;

  const {
    isOpen,
    initialState,
    onCancel,
    onConfirm,
    openDialog,
    closeDialog,
  } = inputState;

  const { defaultValue } = initialState;

  const localState: ClubSettingsGeneralDialogLocalState =
    useClubSettingsGeneralDialogLocalState({
      initialState,
    });

  const tabId = initialState.tabId;

  const { form } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue: ClubSettingsGeneralFormModel = watch();

  const submitForm = useClubSettingsGeneralDialogSubmit({
    handleSubmit,
    localState,
    inputState,
  });

  const hasChanges = form.formState.isDirty;

  return !isOpen ? null : (
    <AppPageContainerWithFixedBars
      className="max-w-screen-2xl"
      contentClassName="bg-gray-50"
      footerBar={() => (
        <ClubSettingsGeneralDialogActionsBar
          className="sm:hidden"
          onCancel={onCancel}
          submitForm={submitForm}
        />
      )}
    >
      <div className="app-p-content">
        {/* <h3 className="mt-2 text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
          Espace client
        </h3> */}

        <AppFormAutoSubmit hasChanges={hasChanges} onSubmit={submitForm}>
          <div className={clsx('app-card app-p-content')}>
            {tabId === 'main' ? (
              <ClubSettingsGeneralMainForm
                form={form}
                initialState={initialState}
              />
            ) : tabId === 'sessions' ? (
              <ClubSettingsGeneralSessionsForm
                form={form}
                initialState={initialState}
              />
            ) : tabId === 'purchase' ? (
              <ClubSettingsGeneralPurchaseEditForm
                form={form}
                initialState={initialState}
              />
            ) : tabId === 'payments' ? (
              <ClubSettingsGeneralPaymentsEditForm
                form={form}
                initialState={initialState}
              />
            ) : null}
          </div>
          <div className="hidden sm:block sticky -bottom-8 pt-8 app-card">
            <div className="app-p-content flex gap-4 justify-start">
              <AppButton
                size="normal"
                icon={AppIcons.action.cancel}
                tabIndex={500}
                color="gray-outline-light"
                onClick={() => {
                  onCancel();
                }}
              >
                Annuler
              </AppButton>
              <AppButton
                type="submit"
                size="normal"
                icon={AppIcons.action.save}
                color="primary-bg"
                onClick={() => {
                  submitForm();
                }}
              >
                Confirmer
              </AppButton>
            </div>
          </div>
        </AppFormAutoSubmit>
      </div>
    </AppPageContainerWithFixedBars>
  );
};
