/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';
import {
  AppButton,
  AppFormAutoSubmit,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubSettingsCommunicationDialogActionsBar } from './ClubSettingsCommunicationDialogActionsBar';

import { AppIcons } from 'src/business/_core/modules/layout/icons';
import {
  ClubSettingsCommunicationDialogState,
  ClubSettingsCommunicationFormModel,
} from '../_model';
import { ClubSettingsCommunicationDialogForm } from './ClubSettingsCommunicationDialogForm';
import {
  ClubSettingsCommunicationDialogLocalState,
  useClubSettingsCommunicationDialogLocalState,
} from './useClubSettingsCommunicationDialogLocalState.hook';
import { useClubSettingsCommunicationDialogSubmit } from './useClubSettingsCommunicationDialogSubmit';

export const ClubSettingsCommunicationDialog: FC<ClubSettingsCommunicationDialogState> =
  (inputState) => {
    const [openExtraCostsFormPanel, setOpenExtraCostsFormPanel] =
      useState(false);
    const globalClasses = useGlobalClasses();

    const clubResume = useClubResume();
    const clubSettings = clubResume.clubSettings;
    const clubReference = clubResume?.reference;

    const {
      isOpen,
      initialState,
      onCancel,
      onConfirm,
      openDialog,
      closeDialog,
    } = inputState;

    const { defaultValue } = initialState;

    const localState: ClubSettingsCommunicationDialogLocalState =
      useClubSettingsCommunicationDialogLocalState({
        initialState,
      });

    const { form } = localState;

    const {
      register,
      handleSubmit,
      watch,
      formState,
      control,
      setValue,
      reset,
    } = form;

    const formValue: ClubSettingsCommunicationFormModel = watch();

    const submitForm = useClubSettingsCommunicationDialogSubmit({
      handleSubmit,
      localState,
      inputState,
    });

    const hasChanges = form.formState.isDirty;

    return !isOpen ? null : (
      <AppPageContainerWithFixedBars
        className="max-w-screen-2xl"
        contentClassName="bg-gray-50"
        footerBar={() => (
          <ClubSettingsCommunicationDialogActionsBar
            className="sm:hidden"
            onCancel={onCancel}
            submitForm={submitForm}
          />
        )}
      >
        <div className=" app-p-content">
          <AppFormAutoSubmit hasChanges={hasChanges} onSubmit={submitForm}>
            <ClubSettingsCommunicationDialogForm localState={localState} />
            <div className="hidden sm:block sticky -bottom-8 pt-8 app-card">
              <div className="app-p-content flex gap-4 justify-start">
                <AppButton
                  size="normal"
                  icon={AppIcons.action.cancel}
                  tabIndex={500}
                  color="gray-outline-light"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Annuler
                </AppButton>
                <AppButton
                  type="submit"
                  size="normal"
                  icon={AppIcons.action.save}
                  color="primary-bg"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  Confirmer
                </AppButton>
              </div>
            </div>
          </AppFormAutoSubmit>
        </div>
      </AppPageContainerWithFixedBars>
    );
  };
